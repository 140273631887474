:root {
  --white: #ffffff;
  --black: #181818;
  --light-yellow: #f4e698;
  --light-yellow-hover: #ead03f;
  --tan: #c9a35e;
  --tan-hover: #a67f38;
  --light-gray: #c5c5c5;
  --medium-gray: #8c8c8c;
  --dark-gray: #525252;
}

#layout {
  #header {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s ease-in-out;
    background-image: url(../../assets/img2.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: var(--black);

    .container {
      text-align: left;
      // border: 1px solid red;
      padding: 0 2rem;
      width: 100%;

      .header-content {
        h1 {
          color: var(--white);
          font-size: 2.6rem;
          transition: all 0.5s ease-in-out;
        }

        #the-subtitle {
          margin-top: 0.5rem;
          font-weight: 400;
          font-size: 1rem;
          color: var(--light-gray);
          span {
            color: var(--light-yellow-hover);
          }
        }
      }

      .navbar {
        position: fixed;
        top: 1.6rem;
        right: 1.4rem;

        @media screen and (min-width: 456px) {
          top: 1.2rem;
          right: 1.4rem;
        }

        .ham-icon {
          font-size: 1.5rem;
          cursor: pointer;
          color: white;
          font-weight: 600;
          transition: transform 0.5s ease-in-out;
          color: var(--white);
        }

        ul {
          position: fixed;
          bottom: -100%;
          left: 0;
          right: 0;
          height: 100vh;
          background-color: var(--dark-gray);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 1.5rem;
          transition: bottom 0.6s ease-in-out;
          list-style: none;
          padding: 2rem;
          margin: 0;

          .close-icon {
            position: relative;
            top: -20%;
            right: -48%;
            font-size: 1.5rem;
            cursor: pointer;
            font-weight: 600;
            color: var(--white);

            &:hover {
              color: var(--light-gray);
            }
          }

          li {
            opacity: 0;
            transform: translateY(20px);
            transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;

            &:nth-child(1) {
              transition-delay: 0.1s;
            }
            &:nth-child(2) {
              transition-delay: 0.2s;
            }
            &:nth-child(3) {
              transition-delay: 0.3s;
            }
            &:nth-child(4) {
              transition-delay: 0.4s;
            }

            .nav-link {
              text-decoration: none;
              color: white;
              font-size: 1.2rem;
              transition: color 0.3s;

              &.active {
                color: var(--light-yellow-hover);
              }

              &:hover {
                color: var(--tan);
              }
            }
          }
        }

        &.open {
          ul {
            bottom: 0;
            animation: slideUp 0.6s ease-in-out;

            li {
              opacity: 1;
              transform: translateY(0);
            }
          }
        }

        @keyframes slideUp {
          from {
            bottom: -100%;
          }
          to {
            bottom: 0;
          }
        }

        @media (min-width: 992px) {
          position: static;
          margin-top: 1.4rem;

          .ham-icon {
            display: none;
          }

          ul {
            position: static;
            height: fit-content;
            // background-color: var(--black);
            background-color: transparent;
            display: flex;
            flex-direction: row;
            // align-items: start;
            justify-content: left;
            // justify-content: space-between;
            gap: 0;
            padding: 0;

            .close-icon {
              display: none;
            }

            li {
              opacity: 1;
              transform: none;
              transition: none;
              margin: 0 1.8rem 0 0;

              .nav-link {
                text-decoration: none;
                color: var(--white);
                display: inline-block;
                position: relative;
                // padding-bottom: 3px;
                font-size: 1rem;

                &:hover {
                  color: var(--light-yellow);
                }
              }

              .nav-link::before {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 0;
                height: 3px;
                background-color: var(--tan);
                transition: width 0.3s ease;
              }

              .nav-link.active::before {
                width: 40%;
                background-color: var(--tan);
              }

              .nav-link:hover::before {
                width: 100%;
                background-color: var(--tan);
              }

              .nav-link.active:hover::before {
                width: 100%;
                background-color: var(--tan);
              }
            }
          }
        }
      }

      .social-links {
        margin-top: 1.6rem;
        a {
          margin-right: 1.4rem;
          svg {
            font-size: 1.6rem;
            color: var(--light-gray);
            transition: all 0.3s ease;

            &:hover {
              transform: scale(1.1);
              color: var(--light-yellow);
            }
          }
        }
      }
    }

    &.clicked {
      height: 10vh;
      transition: all 0.5s ease-in-out;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      background-image: none;

      .container {
        @media (min-width: 992px) {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .navbar {
            margin: 0;
            // border: 1px solid white;
            // height: 100%;
          }
        }
        .header-content {
          display: flex;
          justify-content: space-between;
          align-items: center;

          h1 {
            font-size: 1.5rem;
            transition: all 0.5s ease-in-out;
          }

          #the-subtitle {
            display: none;
          }
        }
        .social-links {
          display: none;
        }
      }
    }
  }

  #content {
    background-color: var(--light-yellow);
    min-height: 100vh;
    height: 100%;
    padding-top: 10vh;
  }
}
