.column {
  display: flex;
  flex-direction: column;
  height: 100vh;
  div {
    width: 3.5rem;
    height: 9%;
    // padding: 1rem;
  }

  .white {
    background-color: var(--white);
  }

  .black {
    background-color: var(--black);
  }

  .light-yellow {
    background-color: var(--light-yellow);
  }

  .light-yellow-hover {
    background-color: var(--light-yellow-hover);
  }

  .tan {
    background-color: var(--tan);
  }

  .tan-hover {
    background-color: var(--tan-hover);
  }

  .light-gray {
    background-color: var(--light-gray);
  }

  .medium-gray {
    background-color: var(--medium-gray);
  }

  .dark-gray {
    background-color: var(--dark-gray);
  }
}
