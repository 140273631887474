:root {
  --white: #ffffff;
  --black: #181818;
  --light-yellow: #f4e698;
  --light-yellow-hover: #ead03f;
  --tan: #c9a35e;
  --tan-hover: #a67f38;
  --light-gray: #c5c5c5;
  --medium-gray: #8c8c8c;
  --dark-gray: #525252;
}

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  list-style: none;
  list-style-type: none;
  text-decoration: none;
  scroll-behavior: smooth;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
body,
html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 1rem;
  font-family: "Poppins", "Times New Roman", Times, serif;
  font-weight: 400;
  text-align: center;
  text-decoration: none;
}
