:root {
    --white: #ffffff;
    --black: #181818;
    --light-yellow: #f4e698;
    --light-yellow-hover: #ead03f;
    --tan: #c9a35e;
    --tan-hover: #a67f38;
    --light-gray: #c5c5c5;
    --medium-gray: #8c8c8c;
    --dark-gray: #525252;
  }
  
  .about-me {
    background-color: var(--white);
    color: var(--black);
    padding: 2rem;
    max-width: 800px;
    margin: auto;
    font-family: Arial, sans-serif;
  
    .title {
      font-size: 2.5rem;
      color: var(--tan);
      text-align: center;
      margin-bottom: 1rem;
    }
  
    .section-title {
      font-size: 2rem;
      color: var(--tan-hover);
      text-align: center;
      margin-bottom: 1rem;
    }
  
    .content {
      font-size: 1rem;
      line-height: 1.6;
  
      p {
        margin-bottom: 1rem;
      }
  
      a {
        color: var(--light-yellow);
        text-decoration: none;
  
        &:hover {
          color: var(--light-yellow-hover);
        }
      }
  
      .subsection-title {
        font-size: 1.5rem;
        color: var(--tan);
        margin-top: 2rem;
        margin-bottom: 0.5rem;
      }
    }
  }
  